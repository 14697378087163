.react-selectize {
  color: #000;
}
.react-selectize.root-node {
  position: relative;
  width: 300px;
}
.react-selectize.root-node.disabled {
  pointer-events: none;
}
.react-selectize.root-node .react-selectize-control {
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  position: relative;
  padding: 2px;
}
.react-selectize.root-node .react-selectize-control .react-selectize-placeholder {
  display: block;
  line-height: 30px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  position: absolute;
  max-width: calc(100% - 56px);
}
.react-selectize.root-node .react-selectize-control .react-selectize-search-field-and-selected-values {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  min-height: 30px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.react-selectize.root-node .react-selectize-control .react-selectize-search-field-and-selected-values .resizable-input {
  background: none;
  border: none;
  outline: none;
  font-size: 1em;
  margin: 2px;
  padding: 4px 0px;
  vertical-align: middle;
  width: 0px;
}
.react-selectize.root-node .react-selectize-control .react-selectize-search-field-and-selected-values .value-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.react-selectize.root-node .react-selectize-control .react-selectize-reset-button-container,
.react-selectize.root-node .react-selectize-control .react-selectize-toggle-button-container {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -o-box-flex: 0;
  -ms-box-flex: 0;
  box-flex: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 30px;
}
.react-selectize.root-node .react-selectize-control .react-selectize-reset-button-container {
  width: 16px;
}
.react-selectize.root-node .react-selectize-control .react-selectize-toggle-button-container {
  width: 32px;
}
.react-selectize.root-node .react-selectize-control .react-selectize-reset-button-container:hover .react-selectize-reset-button path {
  stroke: #c0392b;
}
.react-selectize.root-node .react-selectize-control .react-selectize-reset-button path {
  -webkit-transition: stroke 0.5s 0s ease;
  -moz-transition: stroke 0.5s 0s ease;
  -o-transition: stroke 0.5s 0s ease;
  -ms-transition: stroke 0.5s 0s ease;
  transition: stroke 0.5s 0s ease;
  stroke: #999;
  stroke-linecap: square;
  stroke-linejoin: mitter;
}
.react-selectize.root-node .react-selectize-control .react-selectize-toggle-button path {
  fill: #999;
}
.react-selectize.dropdown-menu-wrapper {
  position: absolute;
}
.react-selectize.dropdown-menu-wrapper.tethered {
  min-width: 300px;
}
.react-selectize.dropdown-menu-wrapper:not(.tethered) {
  width: 100%;
}
.react-selectize.dropdown-menu {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
  position: absolute;
  max-height: 200px;
  z-index: 10;
}
.react-selectize.dropdown-menu.tethered {
  min-width: 300px;
}
.react-selectize.dropdown-menu:not(.tethered) {
  width: 100%;
}
.react-selectize.dropdown-menu .groups.as-columns {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.react-selectize.dropdown-menu .groups.as-columns > div {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.react-selectize.dropdown-menu .option-wrapper {
  cursor: pointer;
  outline: none;
}
.multi-select.react-selectize.root-node .simple-value {
  display: inline-block;
  margin: 2px;
  vertical-align: middle;
}
.multi-select.react-selectize.root-node .simple-value span {
  display: inline-block;
  padding: 2px 5px 4px;
  vertical-align: center;
}
.simple-select.react-selectize.root-node .simple-value {
  margin: 2px;
}
.simple-select.react-selectize.root-node .simple-value span {
  display: inline-block;
  vertical-align: center;
}
.react-selectize.material {
  font-family: Roboto, sans-serif;
}
.react-selectize.material.root-node.open .react-selectize-control:after {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -o-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.react-selectize.material.root-node .react-selectize-control {
  border-bottom: 1px solid rgba(0,0,0,0.3);
}
.react-selectize.material.root-node .react-selectize-control:after {
  background-color: #00bcd4;
  content: "";
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -o-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -moz-transition: -moz-transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -o-transition: -o-transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -ms-transition: -ms-transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  left: 0px;
  bottom: -1px;
  width: 100%;
  height: 2px;
}
.react-selectize.material.root-node .react-selectize-control .react-selectize-placeholder {
  color: rgba(0,0,0,0.3);
  text-indent: 4px;
}
.react-selectize.material.dropdown-menu-wrapper.flipped {
  margin-bottom: 8px;
}
.react-selectize.material.dropdown-menu-wrapper.flipped .dropdown-menu {
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.react-selectize.material.dropdown-menu-wrapper:not(.flipped) {
  margin-top: 8px;
}
.react-selectize.material.dropdown-menu-wrapper:not(.flipped) .dropdown-menu {
  -webkit-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}
.react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-enter-active,
.react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-leave-active {
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -moz-transition: -moz-transform 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -o-transition: -o-transform 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -ms-transition: -ms-transform 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: transform 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-enter {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  transform: scale(0, 0);
}
.react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-enter-active {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-leave {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-leave-active {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.react-selectize.material.dropdown-menu {
  background-color: #fff;
  border-radius: 2px;
  -webkit-box-shadow: rgba(0,0,0,0.118) 0px 1px 6px, rgba(0,0,0,0.118) 0px 1px 4px;
  box-shadow: rgba(0,0,0,0.118) 0px 1px 6px, rgba(0,0,0,0.118) 0px 1px 4px;
  max-height: 250px;
  padding: 8px 0px;
}
.react-selectize.material.dropdown-menu.flipped {
  margin-bottom: 8px;
}
.react-selectize.material.dropdown-menu:not(.flipped) {
  margin-top: 8px;
}
.react-selectize.material.dropdown-menu .no-results-found {
  font-style: oblique;
  font-size: 16px;
  height: 32px;
  padding: 0px 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.react-selectize.material.dropdown-menu .groups:not(.as-columns) > div:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}
.react-selectize.material.dropdown-menu .simple-group-title {
  color: #8f8f8f;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 14px;
  height: 48px;
  padding: 0px 10px;
}
.react-selectize.material.dropdown-menu .option-wrapper.highlight {
  background-color: rgba(0,0,0,0.098);
}
.react-selectize.material.dropdown-menu .option-wrapper .simple-option {
  color: rgba(0,0,0,0.875);
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-size: 16px;
  height: 48px;
  padding: 0px 16px;
}
.react-selectize.material.dropdown-menu .option-wrapper .simple-option.not-selectable {
  background-color: #f8f8f8;
  color: #999;
  cursor: default;
  font-style: oblique;
  text-shadow: 0px 1px 0px #fff;
}
.multi-select.react-selectize.material.root-node .simple-value span {
  padding: 0px;
}
.multi-select.react-selectize.material.root-node .simple-value span:after {
  content: ",";
}
.simple-select.react-selectize.material.root-node .simple-value {
  margin: 4px 3px 3px 2px;
}
